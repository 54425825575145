<template>
    <div class="SalaryQuery_component">
        <div class="SalaryQueryResult_singleShow" v-if="result.shebao.length > 0 || result.gongjijin.length > 0">
            <h3 class="title">{{ result.city }}</h3>
            <div class="cont">社会保险公积金缴纳标准为：</div>
            <div class="module shebao">
                <div class="module-header">
                    <div class="module-title">社保</div>
                </div>
                <div class="module-content">
                    <div class="table">
                        <div class="thead">
                            <div class="row">
                                <div class="cell">缴纳项目</div>
                                <div class="cell">下限</div>
                                <div class="cell">上限</div>
                                <div class="cell">个人</div>
                                <div class="cell">单位</div>
                            </div>
                        </div>
                        <div class="tbody">
                            <div class="row" v-for="(row, rowIndex) in result.shebao" :key="'shebao' + rowIndex">
                                <div class="cell">{{ row.name }}</div>
                                <div class="cell">{{ row.lowerLimit }}</div>
                                <div class="cell">{{ row.upperLimit }}</div>
                                <div class="cell">{{ row.person }}</div>
                                <div class="cell">{{ row.unit }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module gongjijin">
                <div class="module-header">
                    <div class="module-title">公积金</div>
                </div>
                <div class="module-content">
                    <div class="table">
                        <div class="thead">
                            <div class="row">
                                <div class="cell">缴纳项目</div>
                                <div class="cell">下限</div>
                                <div class="cell">上限</div>
                                <div class="cell">个人</div>
                                <div class="cell">单位</div>
                            </div>
                        </div>
                        <div class="tbody">
                            <div class="row" v-for="(row, rowIndex) in result.gongjijin" :key="'gongjijin' + rowIndex">
                                <div class="cell">{{ row.name }}</div>
                                <div class="cell">{{ row.lowerLimit }}</div>
                                <div class="cell">{{ row.upperLimit }}</div>
                                <div class="cell">{{ row.person }}</div>
                                <div class="cell">{{ row.unit }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 缺省 -->
        <div class="SalaryQueryResult_empty" v-else>
            <van-empty description="暂无该地区数据" />
        </div>
        <!--注释 -->
        <div class="description">百分比为缴纳比例，值为固定金额</div>
    </div>
</template>

<script>
    import { Empty } from "vant";
    export default {
        name: 'SocialSecurityPayment',
        props: {
            result: Object
        },
        components: {
            [Empty.name]: Empty,
        }
    };
</script>

<style lang="scss" scoped>
    @import url("./style.css");
    .module {
        margin-top: 10px;
        .module-header {
            position: relative;
            padding: 10px 10px;
            font-size: 15px;
            .module-title {
                font-weight: bold;
                &:before {
                    content: '';
                    width: 5px;
                    height: 20px;
                    background-color: #333;
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                }
            }
        }
        &.shebao {
            .module-header {
                .module-title:before {
                    background-color: #1989fa;
                }
            }
        }
        &.gongjijin {
            .module-header {
                .module-title:before {
                    background-color: #e6a23c;
                }
            }
        }
    }
    .thead {
        .row {
            color: #333;
            font-weight: bold;
            font-size: 13px;
            border-top: 1px solid #1989fa;
            border-bottom: 1px solid #1989fa;
        }
    }
    .tbody {
        font-size: 13px;
        .row {
            border-bottom: 1px solid #ebedf0;
            &:last-child {
                border-bottom: none
            }
        }
    }
    .thead,
    .tbody {
        .row {
            display: flex;
        }
        .cell {
            padding: 6px 2px;
            text-align: center;
            flex: 1;
            &:first-child {
                flex: 1.3;
            }
        }
    }
    .description {
        font-size: 12px;
        margin-top: 15px;
        &:before {
            content: '*';
            font-size: 18px;
            vertical-align: text-top;
            margin-right: 3px;
        }
    }
</style>
