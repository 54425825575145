<template>
  <div class="SalaryQuery_component">
    <!-- 单个显示 -->
    <div class="SalaryQueryResult_singleShow" v-if="result.showList == '0' && result.resultAvgList && result.resultAvgList.length">
      <h3 class="title">{{ result.city }}</h3>
      <div class="cont"  v-for="(avg, idx) in result.resultAvgList" :key="idx">
        目前执行标准年度平均工资为<b class="num">{{ avg.sndpjgz }}</b>元，月度平均工资为<b class="num">{{ avg.ypjgz }}</b>元
      </div>
    </div>

    <!-- 列表显示 -->
    <div class="SalaryQueryResult_listShow" v-else-if="result.showList == '1' && result.resultAvgList && result.resultAvgList.length">
      <h3 class="title">以下为{{ result.city }}部分税务局平均工资情况：</h3>
      <div class="cont" v-for="(avg, idx) in result.resultAvgList" :key="idx">
        <p class="c1">{{ avg.zgswjg }}地区</p>
        <p class="c2">
          目前执行标准年度平均工资为 <b class="num">{{ avg.sndpjgz }}</b> 元，月度平均工资为 <b class="num">{{ avg.ypjgz }}</b> 元
        </p>
      </div>
    </div>

    <!-- 缺省 -->
    <div v-else class="SalaryQueryResult_empty">
      <van-empty description="暂无该地区数据" />
    </div>

    <!--注释 -->
    <div class="tips">
      注释:
      社平工资是社会职工的平均工资的简称，通常指某一地区或国家一定时期内（通常为一年）全部职工工资总额除以这一时期内职工人数后所得的平均工资；此处平均工资专指计算经济补偿金使用的平均工资, 而非使用五险一金的基数。
    </div>
  </div>
</template>

<script>
import { Empty } from "vant";
export default {
  props: {
    queryInfo: Object,
    result: Object
  },
  components: {
    [Empty.name]: Empty,
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
</style>