<template>
  <div class="SalaryQuery_component">
    <!-- 单个显示 -->
    <div class="SalaryQueryResult_singleShow" v-if="result.num == 1 && result.reusltMinList && result.reusltMinList.length">
      <h3 class="title">{{ result.city }}</h3>
      <div class="cont" v-for="(avg, idx) in result.reusltMinList" :key="idx">
        目前执行标准{{ avg.county || '' }}月最低工资为<b class="num">{{ avg.yearMinWage }}</b>元，
        小时最低工资为<b class="num">{{ avg.hourMinWage }}</b>元
      </div>
    </div>

    <!-- 列表显示 -->
    <div class="SalaryQueryResult_listShow" v-else-if="result.num > 1 && result.reusltMinList && result.reusltMinList.length">
      <h3 class="title">{{ result.year }}年度{{ result.city }}最低工资标准共有{{ result.num }}个档位，其中：</h3>
      <div class="cont" v-for="(avg, idx) in result.reusltMinList" :key="idx">
        <p class="c2">
          {{ avg.county || '' }}处于{{ avg.gear }}，目前执行标准月最低工资为 <b class="num">{{ avg.yearMinWage }}</b> 元，小时最低工资为 <b class="num">{{ avg.hourMinWage }}</b> 元；
        </p>
      </div>
    </div>

    <!-- 缺省 -->
    <div v-else class="SalaryQueryResult_empty">
      <van-empty description="暂无该地区数据" />
    </div>

    <!-- 执行文件时间 -->
    <div class="executeDate" v-if="result.reusltMinList && result.reusltMinList.length">
      <p>{{ result.reusltMinList[0].file }}</p>
      <p>执行时间为 {{ result.reusltMinList[0].executeDate }}</p>
    </div>

    <!--注释 -->
    <div class="tips">
      注释: 
      最低工资标准是保障劳动者最低工资收入的法律制度，是一种适用于全行业的国家标准。
    </div>
  </div>
</template>

<script>
import { Empty } from "vant";
export default {
  props: {
    queryInfo: Object,
    result: Object
  },
  components: {
    [Empty.name]: Empty,
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
</style>
