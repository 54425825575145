<template>
  <div class="SalaryQuery_component">
    <!-- 单个显示 -->
    <div class="SalaryQueryResult_singleShow" v-if="result.showList == '0' && result.resultAnnuityList && result.resultAnnuityList.length">
      <h3 class="title">{{ result.city }}</h3>
      <div
        class="cont"
        v-for="(avg, idx) in result.resultAnnuityList"
        :key="idx"
      >
        目前执行标准企业年金免税上限为
        <b class="num">{{ avg.njsx }}</b>元
      </div>
    </div>
    
    <!-- 列表显示 -->
    <div class="SalaryQueryResult_listShow" v-else-if="result.showList == '1' && result.resultAnnuityList && result.resultAnnuityList.length">
      <h3 class="title">
        以下为{{ result.city }}部分税务局企业年金免税上限情况：
      </h3>
      <div
        class="cont"
        v-for="(avg, idx) in result.resultAnnuityList"
        :key="idx"
      >
        <p class="c1">{{ avg.zgswjg }}地区</p>
        <p class="c2">
          目前执行标准企业年金免税上限为
          <b class="num">{{ avg.njsx }}</b> 元
        </p>
      </div>
    </div>

    <!-- 缺省 -->
    <div v-else class="SalaryQueryResult_empty">
      <van-empty description="暂无该地区数据" />
    </div>

    <!--注释 -->
    <div class="tips">
      注释:
      个人根据国家有关政策规定缴付的年金个人缴费部分，在不超过本人缴费工资计税基数的4%标准内的部分，暂从个人当期的应纳税所得额中扣除。超过部分，应并入个人当期的工资、薪金所得，依法计征个人所得税。税款由建立年金的单位代扣代缴。
    </div>
  </div>
</template>

<script>
import { Empty } from "vant";
export default {
  props: {
    queryInfo: Object,
    result: Object
  },
  components: {
    [Empty.name]: Empty,
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
</style>