<template>
  <div class="SalaryQuery_component">
    <!-- 单个显示 -->
    <div class="SalaryQueryResult_singleShow" v-if="result.showList == '0' && result.resultGongjijinList && result.resultGongjijinList.length">
      <h3 class="title">{{ result.city }}</h3>
      <div
        class="cont"
        v-for="(avg, idx) in result.resultGongjijinList"
        :key="idx"
      >
        目前执行标准住房公积金免税上限为
        <b class="num">{{ avg.zfgjjsx }}</b>元
      </div>
    </div>

    <!-- 列表显示 -->
    <div class="SalaryQueryResult_listShow" v-else-if="result.showList == '1' && result.resultGongjijinList && result.resultGongjijinList.length">
      <h3 class="title">
        以下为{{ result.city }}部分税务局住房公积金免税上限情况：
      </h3>
      <div
        class="cont"
        v-for="(avg, idx) in result.resultGongjijinList"
        :key="idx"
      >
        <p class="c1">{{ avg.zgswjg }}地区</p>
        <p class="c2">
          目前执行标准住房公积金免税上限为
          <b class="num">{{ avg.zfgjjsx }}</b> 元
        </p>
      </div>
    </div>

    <!-- 缺省 -->
    <div v-else class="SalaryQueryResult_empty">
      <van-empty description="暂无该地区数据" />
    </div>

    <!--注释 -->
    <div class="tips">
      注释: 
      单位和职工个人缴存住房公积金的月平均工资不得超过职工工作地所在设区城市上一年度职工月平均工资的3倍，超过上述规定比例和标准缴付的住房公积金，应将超过部分并入个人当期的工资、薪金收入，计征个人所得税。
    </div>
  </div>
</template>

<script>
import { Empty } from "vant";
export default {
  props: {
    queryInfo: Object,
    result: Object
  },
  components: {
    [Empty.name]: Empty,
  },
};
</script>

<style lang="scss" scoped>
@import url("./style.css");
</style>